import { useStaticQuery, graphql } from 'gatsby'

export const QueryWork = () => {
  const query = useStaticQuery(
    graphql`
      {
        sanityWork {
          id
          internal {
            type
            content
            contentDigest
            owner
          }
        }
      }
    `
  )
  return JSON.parse(query.sanityWork.internal.content)
}
